import React from "react"
import { graphql } from "gatsby"

import { Link } from "@reach/router"
import SEO from "../../components/seo"
import PageTitle from "../../components/shared/PageTitle"
import RegDropDown from "../../components/Registration/RegDropDown"
import OL from "../../components/shared/OrderedList"
import Faqs from "../../components/Faqs"

export const IndentedItem = ({ children = "", className = "" }) => (
  <div className={`pl-5 ${className}`}>{children}</div>
)

const PlayersPage = ({ data }) => {
  const mainContentDocument = data?.contentfulRegistrationPage?.mainContent
  const spordleLink =
    "https://page.spordle.com/sioux-lookout-hudson-minor-hockey-association"
  return (
    <div className="px-5 mb-10">
      <SEO
        title="Player Registration"
        keywords={[`Sioux Lookout`, `hockey`, `Registration`, `info`]}
      />
      <PageTitle>Player Registration</PageTitle>

      <div>
        To Register your child as a Player with Sioux Lookout Minor Hockey, use
        the following steps below:
      </div>

      <ol className="my-10 break-words grid gap-y-5 ml-0 md:ml-8">
        <RegDropDown
          title="Register with Hockey Canada"
          mainLinktext="Register"
          mainLinkUrl={spordleLink}
          redButton
        >
          <OL>
            <li>
              a.{" "}
              <a target="_blank" href={spordleLink} rel="noreferrer noopener">
                Follow the link,
              </a>{" "}
              Click "Register Now"
            </li>

            <li>
              b. See list of costs and birth years associated with each
              Division, click “Register Now”
            </li>
            <li>
              c. Login with Hockey Canada registry (email and password with
              Spordle account)
            </li>
            <IndentedItem className="text-sm italic">
              * if new to Hockey Canada, create an account (remember login
              information for future!)
            </IndentedItem>
            <li>d. Fill in the information as requested</li>
            <li>e. Payment:</li>
            <IndentedItem>
              <ol className="list-disc">
                <li>
                  <span className="font-bold">Pay Fees online</span> using
                  credit card in during registration process (previous steps)
                </li>
                <li>
                  <span className="font-bold">
                    Pay by Email Money Transfer (EMT) to:
                  </span>{" "}
                  slmhatreasurer@gmail.com
                </li>
                <li>
                  <span className="font-bold">
                    For any special payment requests
                  </span>{" "}
                  please email the Treasurer at: slmhatreasurer@gmail.com
                </li>
              </ol>
            </IndentedItem>
          </OL>
        </RegDropDown>
        <RegDropDown
          title="Complete Respect in Sport for Parent"
          mainLinktext="Respect In Sport"
          mainLinkUrl="https://hnwo.respectgroupinc.com/koala_final/"
        >
          <OL>
            <li>a. Click link</li>
            <li className="text-indent">
              b. Login using the Hockey Canada Spordle account information (Step
              1 – Hockey Canada)
            </li>
            <li>c. Register for Course (fees are not reimbursable)</li>
            <li>d. Select “Program Access”</li>
            <li>e. Complete Modules (watch)</li>
            <li>
              f. Once completed, you are done! (your Hockey Canada account will
              show that you have completed the course)
            </li>
          </OL>
        </RegDropDown>
        <RegDropDown
          title="Fill Out Medical Form"
          mainLinktext="Medical Form"
          mainLinkUrl="https://forms.gle/no9MJQnhJSMjiStE7"
        ></RegDropDown>
        <RegDropDown title="Fill Out Proof Of Id">
          <div>Scan or photograph a proof of ID and email it to [....]</div>
          <div>
            Please label your email with the subject “Proof of ID - &lt;Player
            Name and Division&gt;”
          </div>
        </RegDropDown>
      </ol>
      <div className="mb-10">
        <h2 className="text-3xl xl:text-4xl mb-3">
          Frequently Asked Questions
        </h2>
        <Faqs />
      </div>

      <p style={{ textAlign: "center" }}>
        <Link to="/contact">Contact us</Link> for more information.
      </p>
    </div>
  )
}

export default PlayersPage

export const pageQuery = graphql`
  query playerRegistrationQuery {
    registrationImg: file(relativePath: { eq: "sampleImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrationForm: contentfulDocument(
      title: { eq: "SLMHA 2020 2021 Season Registration" }
    ) {
      file {
        file {
          url
        }
      }
    }
    contentfulRegistrationPage {
      mainContent {
        raw
      }
    }
  }
`
