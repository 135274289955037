import React, { useState } from "react"
import { MdArrowDropDown, MdArrowOutward } from "react-icons/md"

export const StepTitle = ({ children = "" }) => (
  <h2 className="ml-3 text-3xl xl:text-4xl">
    <li>{children}</li>
  </h2>
)

const RegDropDown = ({
  title = "TITLE",
  mainLinkUrl = "",
  mainLinktext = "TEXT HERE",
  children,
  redButton = false,
}) => {
  const [showContent, setShowContent] = useState(false)
  const handleToggleContent = () => {
    setShowContent(prev => !prev)
  }
  return (
    <>
      <StepTitle>
        <button onClick={handleToggleContent} className="text-left flex">
          {title}

          <MdArrowDropDown
            className={`transform duration-200 ${
              showContent ? "" : "-rotate-180"
            } transition-all`}
          />
        </button>
      </StepTitle>

      <div
        className={`${showContent ? "flex" : "hidden"} flex-col items-start 0`}
      >
        {mainLinkUrl && (
          <a
            href={mainLinkUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={`no-underline cursor-pointer flex items-center gap-x-1 justify-start ${
              redButton
                ? "bg-red-600 hover:bg-red-700"
                : "bg-blue-500 hover:bg-blue-700"
            }  text-white font-bold py-2 px-4 rounded ml-7 mb-4 `}
          >
            {mainLinktext} <MdArrowOutward />
          </a>
        )}
        {children}
      </div>
    </>
  )
}

export default RegDropDown
