import React from "react"

const Faqs = () => {
  return (
    <ol>
      {faqData.map((faq, index) => {
        return (
          <li key={index} className="pl-5 mt-5">
            <div className="font-bold mb-2">{faq.title}</div>
            <div className="pl-5">{faq.content}</div>
          </li>
        )
      })}
    </ol>
  )
}

const faqData = [
  {
    title: "What age group/Division is my child in?",
    content: (
      <>
        <p>This is listed in the Registration process along with the costs.</p>
      </>
    ),
  },
  {
    title: "When is the deadline to register?",
    content: <p>Registration deadlines can be found here:...?</p>,
  },
  {
    title: "Hockey Canada says I already have an account?",
    content: (
      <p>
        Your name may already be in the Hockey Canada system from when you were
        a child/player or coach in previous years. Using the “forgot my
        password” may be a useful tool. If the problem persists please reach out
        to us through our Communications Manager (contact is on this website).
      </p>
    ),
  },
  {
    title: "How do I register for ‘AA’?",
    content: (
      <p>
        Register with the Division of your age group. Details of tryouts and
        registration forms will follow through our communication lines.
      </p>
    ),
  },
  {
    title: "What equipment does my child need?",
    content: (
      <>
        <p>This is a useful website for hockey equipment descriptions:</p>
        <p>
          <a
            href="https://newtohockey.com/hockey-equipment-guide-for-new-adult-players/ "
            target="_blank"
            rel="noreferrer noopener"
          >
            https://newtohockey.com/hockey-equipment-guide-for-new-adult-players/
          </a>
        </p>
      </>
    ),
  },
  {
    title:
      "My child would like to play up/down to a different division from their age group for a specific reason. How do I request this?",
    content: (
      <p>
        Register with the Division of your age group. Email your Division
        convenor with your request.
      </p>
    ),
  },
  {
    title: "How do I check what Coaching qualifications I already have?",
    content: (
      <p>
        Login to your Hockey Canada account (through Spordle) to access your
        profile information.
      </p>
    ),
  },
  {
    title:
      "My child played for another Hockey organization/Branch last year. What do I do?",
    content: (
      <p>Email our Communications Manager (contact is on this website).</p>
    ),
  },
]

export default Faqs
