import React from "react"

const OL = ({ children, className = "" }) => {
  return (
    <ol className={`list-none grid gap-y-2 ml-3 md:ml-8 ${className}`}>
      {children}
    </ol>
  )
}

export const Li2 = ({ children, className }) => {
  return <li className={`pl-5 ${className}`}>{children}</li>
}
export default OL
